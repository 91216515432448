import logo from "./../../assets/images/NECDOT PNG.png";
import scroll_gif from "./../../assets/images/scroll-page.gif";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <div className="header">
      <header>
        <div className="logotype">
          <img loading="lazy" src={logo} alt="Logotipo de necodt" />
        </div>

        <nav>
          <li>
            <Link href="#">Inicio</Link>
          </li>
          <li>
            <Link href="#about_us">¿Quiénes Somos?</Link>
          </li>
          <li>
            <Link href="#services">Servicios</Link>
          </li>
          <li>
            <Link href="#team">Nuestro Equipo</Link>
          </li>
          <li>
            <Link href="#contact_us">Contáctanos</Link>
          </li>
        </nav>

        <i className="fas fa-bars icon-menu button-menu"></i>
      </header>
      <div className="menu-responsive" data-active="false">
        <nav className="menu-responsive__nav">
          <li className="menu-responsive__li">
            <Link className="menu-responsive__a" href="/">
              Inicio
            </Link>
          </li>
          <li className="menu-responsive__li">
            <Link className="menu-responsive__a" href="#about_us">
              ¿Quiénes Somos?
            </Link>
          </li>
          <li className="menu-responsive__li">
            <Link className="menu-responsive__a" href="#services">
              Servicios
            </Link>
          </li>
          <li className="menu-responsive__li">
            <Link className="menu-responsive__a" href="#team">
              Nuestro Equipo
            </Link>
          </li>
          <li className="menu-responsive__li">
            <Link className="menu-responsive__a" href="#contact_us">
              Contáctanos
            </Link>
          </li>
        </nav>
      </div>

      <div className="text">
        <h1>
          En Necodt encontramos soluciones para <b>tu Empresa</b>
        </h1>
        <p>
          Creamos soluciones a nivel informático para tu empresa o
          emprendimiento, tenemos diferentes maneras para hacer que tenga un
          crecimiento multiplicador
        </p>
      </div>
      <div className="socials-icons">
        <div className="content">
          <i className="fab fa-twitter icon"></i>
          <p>Twitter</p>
        </div>

        <div className="content">
          <Link
            to="https://www.facebook.com/NECODT"
            target="_blank"
            className="blanco"
          >
            <i className="fab fa-facebook-f icon"></i>
          </Link>
          <p>Facebook</p>
        </div>

        <div className="content">
          <Link
            to="https://www.instagram.com/necodt/"
            target="_blank"
            className="blanco"
          >
            <i className="fab fa-instagram icon"></i>
          </Link>
          <p>Instagram</p>
        </div>
      </div>

      <center>
        <img loading="lazy" src={scroll_gif} className="scroll-icon" alt="" />
      </center>
    </div>
  );
};

export { Header };
