import React from "react";

const AboutUs = () => {
  return (
    <div className="about-us" id="about_us">
      <div className="text-about">
        <h2>¿Quienes Somos?</h2>
        <div className="line"></div>
        <p>
          Necodt es una empresa que ayuda al crecimiento de pequeñas medianas y
          grandes empresas en el área de tecnología. Contamos con profesionales
          como desarrolladores, ingenieros, diseñadores y comunicadores
          debidamente capacitados
        </p>
        <p>
          Nuestro equipo en conjunto hacen maravillas, proponen, crean, aportan
          ideas geniales, para que todo proyecto salga de una manera fascinante
          y exitoso. Con confianza podemos dejar que sus mentes brillantes hagan
          cumplir las expectativas exigidas
        </p>
      </div>

      <div className="img"></div>
    </div>
  );
};

export { AboutUs };
