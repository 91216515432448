import React from "react";

const Services = ({ title, servicesClass, descripcion, img }) => {
  return (
    <>
      <div className={`content ${servicesClass}`} key={title}>
        <img loading="lazy" src={img} alt={title} />
        <p className="title-services">{title}</p>
        <p className="description-services">{descripcion}</p>
      </div>
    </>
  );
};

export { Services };
