import { AboutUs } from "../../components/about_us/AboutUs";
// Icons
import webDesignImg from "./../../assets/images/iconos/web-development.png";
import mobileDevImg from "./../../assets/images/iconos/mobile-development.png";
import designImg from "./../../assets/images/iconos/graphic-design.png";
import cmImg from "./../../assets/images/iconos/community-manager.png";
import DigitalMktImg from "./../../assets/images/iconos/digital-marketing.png";
// Teams imgs
import DarvinImg from "./../../assets/images/team/Darvin_Rodriguez_1.jpg";
import { Services } from "../../components/Services/Services";
import { Team } from "../../components/team/Team";

//  porfolio img
import kathestevez from "./../../assets/images/portfolio/katerin.webp";
import lionpage from "./../../assets/images/portfolio/lionpage.webp";
import tijuana from "./../../assets/images/portfolio/tijuana.webp";
import { Portafolio } from "../../components/portafolio/Portafolio";
import { ContactForm } from "../../components/contact_form/Contact_form";

function Home() {
  const services = [
    {
      title: "Desarrollo Web",
      serviceClass: "web-design",
      descripcion:
        "Creamos y diseñamos tu página o sitio web según tus necesidades. Aplicando tecnologías tales como HTML5, CSS3 y JS, incluido el responsive design.",
      img: webDesignImg,
    },
    {
      title: "Desarrollo de Apps",
      serviceClass: "app-design",
      descripcion:
        "Creamos y diseñamos aplicaciones móviles que ayuden al funcionamiento interno y/o con los clientes de tu empresa, adaptándola a tus requerimientos e ideas.",
      img: mobileDevImg,
    },
    {
      title: "Diseño Gráfico",
      serviceClass: "graphic-design",
      descripcion:
        "Realizamos el branding de tu marca, es decir, todo lo relacionado a la imagen empresarial (logos, banners, flyers y publicidad en general).",
      img: designImg,
    },
    {
      title: "Community Managment",
      serviceClass: "community-manager",
      descripcion:
        "Manejamos y administramos tus Redes Sociales, creando contenido de valor, teniendo interacción con tus seguidores y aumentando de forma orgánica tu comunidad.",
      img: cmImg,
    },
    {
      title: "Digital Marketing",
      serviceClass: "marketing-digital",
      descripcion:
        "Nuestro equipo de Community Managers aplica una serie de técnicas que permitirá generar ventas y seguidores por Redes Sociales satisfactoriamente.",
      img: DigitalMktImg,
    },
  ];

  const teams = [
    {
      name: "Darvin Rodriguez",
      position: "CEO - Desarrollador Web",
      img: DarvinImg,
      descrition:
        "Soy experta en marketing digital ademas de ser experta en Community Manager",
    },
  ];

  const porfolio = [
    {
      name: "Katherine Estevez | Finanzas Personales",
      descrition: "Web Site financiero / personal",
      img_url: kathestevez,
      page_url: "http://kathestevez.com",
    },
    {
      name: "Clan Lion",
      descrition: "Sitio Web clan de arma 3",
      img_url: lionpage,
      page_url: "http://clanlion.com",
    },
    {
      name: "Ayuntamiento de Tijuana",
      descrition: "Sitio Web gubernamental",
      img_url: tijuana,
      page_url: "https://www.tijuana.gob.mx",
    },
  ];
  return (
    <div>
      <AboutUs />
      <div className="title" id="services">
        <h2>Servicios</h2>
        <div className="line"></div>
      </div>
      <div className="services">
        {services.map((service, index) => (
          <Services
            key={index}
            title={service.title}
            servicesClass={service.serviceClass}
            descripcion={service.descripcion}
            img={service.img}
          />
        ))}
      </div>
      {/* Team */}
      <section className="team" id="team">
        <div className="container-title">
          <h2 className="container-title__name-section">Nuestro Equipo</h2>
        </div>
        <div className="glide team__container">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {teams.map((team, index) => (
                <Team
                  key={index}
                  name={team.name}
                  img={team.img}
                  description={team.description}
                  position={team.position}
                />
              ))}
            </ul>
          </div>
        </div>
      </section>
      {/* Projects */}
      <section className="portfolio" id="portafolio ">
        <div className="container-title">
          <h2 className="container-title__name-section" data-url="prueba.jpg ">
            Portafolio de Trabajo
          </h2>
        </div>
        <div className="portfolio__container-description">
          <p className="portfolio__text-description">Nuestros proyectos</p>
        </div>
        <div className="portfolio__container-work">
          {porfolio.map((element, index) => (
            <Portafolio
              img_url={element.img_url}
              name={element.name}
              description={element.descrition}
              url_page={element.page_url}
              key={index}
            />
          ))}
        </div>
      </section>
      <ContactForm />
    </div>
  );
}

export { Home };
