import React from "react";
import { Link } from "react-router-dom";
const Portafolio = ({ img_url, name, description, url_page }) => {
  return (
    <div class="portfolio__work">
      <img
        loading="lazy"
        src={img_url}
        alt="Imagen ayuntamiento de tijuana"
        class="portfolio__image"
      />
      <div class="portfolio__container-info">
        <div class="portfolio__container-texts">
          <div class="portfolio__texts">
            <p class="portfolio__name">{name}</p>
            <p class="portfolio__description">{description}</p>
          </div>
          <div class="portfolio__container-button">
            <Link target="_blank" to={url_page} class="portfolio__button">
              Visitar
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Portafolio };
