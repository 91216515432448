import React from "react";

const Team = ({ name, img, description, position }) => {
  return (
    <li className="glide__slide team__person">
      <img
        loading="lazy"
        className="team__image"
        src={img}
        draggable="false "
        alt={name}
      />
      <p className="team__name">{name}</p>
      <p className="team__area">{position}</p>
      <p className="team__rol">{description}</p>
    </li>
  );
};

export { Team };
