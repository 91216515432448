import React from "react";
import form_img from "./../../assets/images/contact-form-image.webp";
const ContactForm = () => {
  return (
    <form className="form-contact" id="contact_us">
      <div className="form-contact__left">
        <img
          loading="lazy"
          src={form_img}
          alt="Persona sonriendo "
          className="form-contact__image"
        />
        <div className="form-contact__container-texts">
          <h2 className="form-contact__name">Necodt</h2>
          <p className="form-contact__description">
            Contáctanos y Crece con nosotros!
          </p>
        </div>
      </div>
      <div className="form-contact__right">
        <div className="form-contact__container-title">
          <h2 className="form-contact__title">Formulario de Contácto</h2>
        </div>
        <div className="form-contact__line-title"></div>
        <div className="form-contact__container-inputs">
          <div className="form-contact__group-input">
            <div className="form-contact__c-input">
              <label className="form-contact__label" for="nombre ">
                Nombre Completo
              </label>
              <input name="name" className="form-contact__input" type="text " />
            </div>
            <div className="form-contact__c-input">
              <label className="form-contact__label" for="telefono ">
                Número Telefónico
              </label>
              <input
                name="telephone"
                className="form-contact__input"
                type="text "
              />
            </div>
          </div>
          <div className="form-contact__c-input">
            <label className="form-contact__label" for="email ">
              Correo Electrónico
            </label>
            <input className="form-contact__input" name="email" type="text " />
          </div>
          <div className="form-contact__c-input">
            <label className="form-contact__label" for="mensaje ">
              Mensaje
            </label>
            <textarea
              name="message"
              className="form-contact__input textarea"
              type="text"
            ></textarea>
          </div>
          <div className="form-contact__container-button">
            <button className="form-contact__button-submit" type="submit ">
              Contactar
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export { ContactForm };
