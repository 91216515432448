import logo from "./../../assets/images/NECDOT PNG.png";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <div className="content-footer">
        <div className="necodt-text">
          <img loading="lazy" src={logo} alt=" " />
          <p>
            Necodt cuenta con profesionales como desarrolladores, ingenieros,
            diseñadores y comunicadores debidamente capacitados
          </p>
          <p>
            Nuestro equipo en conjunto hacen maravillas, proponen, crean,
            aportan ideas geniales, para que todo proyecto salga de una manera
            fascinante y exitoso. Con confianza podemos dejar que sus mentes
            brillantes hagan cumplir las expectativas exigidas
          </p>
        </div>

        <div className="info-contact">
          <p className="title-info">Información de contacto</p>
          <p className="info">
            <i className="fas fa-phone-alt icon-info"></i> +1 809-416-0897
          </p>
          <p className="info">
            <i className="fas fa-envelope icon-info"></i> info@necodt.com
          </p>
          <p className="info">
            <i className="fas fa-map-marker-alt icon-info"></i> República
            Dominicana, Santo Domingo Este. C/María Trinidad Sánchez #4
          </p>
        </div>
      </div>
      <footer>
        <p>
          &copy; Todos los derechos reservados Necodt -{" "}
          {new Date().getFullYear()}
          <span id="currentDate"></span>
        </p>
        <Link href="/us-us" className="idiomas">
          English
        </Link>
      </footer>
    </>
  );
}

export { Footer };
