import "@fortawesome/fontawesome-free/css/all.min.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./../assets/css/normalize.css";
import "./../assets/css/import-fonts.css";
import "./../assets/css/variables.css";
import "./../assets/css/App.css";
import { Layout } from "../layout/Layout";
import { Home } from "../view/home/Home";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
